const resizeObserver = new ResizeObserver(() => {
    $(".tab-content").css('height', 'auto');
    $(".tab-pane").css('width', '100%');
});

let selectedSuppliers = [];
let activeSteps = [];

window.CreateSupplyTaskRequest = {
    init() {
        this.initWizard();
        this.handleHiddenSteps();
        this.handleModalShow();
        this.handleButtonClicks();
        this.handleSupplierSelection();
        this.validateDealSampleRequestForm();
        this.validateDealInformationRequestForm();
        this.validateDealPricingRequestForm();
    },

    validateDealSampleRequestForm() {
        $('#sample-request-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            rules: {
                "deal_sample_request[sample_details]": {
                    required: true
                },
                "deal_sample_request[recipient_name]": {
                    required: true
                },
                "deal_sample_request[deadline]": {
                    required: true
                },
                "deal_sample_request[delivery_address]": {
                    required: true
                }
            },
            messages: {
                'deal_sample_request[sample_details]': 'Please provide any relevant details suppliers should know before sending the samples',
                'deal_sample_request[recipient_name]': 'Please provide the recipient\'s name',
                'deal_sample_request[deadline]': 'Minimum 2 weeks from today',
                'deal_sample_request[delivery_address]': 'Please provide the address for delivery'
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    },

    validateDealInformationRequestForm() {
        $('#information-request-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            rules: {
                "deal_information_request[details]": {
                    required: true
                },
                "deal_information_request[deadline]": {
                    required: true
                }
            },
            messages: {
                'deal_information_request[details]': 'Please provide the additional information you need from suppliers',
                'deal_information_request[deadline]': 'Minimum 2 days from today'
            },
            errorPlacement: (error, element) => {
                if (element.attr('name') === 'deal_information_request[details]') {
                    error.insertAfter(element.parent());
                } else {
                    error.insertAfter(element);
                }
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    },

    validateDealPricingRequestForm() {
        $('#pricing-request-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            rules: {
                "deal_pricing_request[incoterm_id]": {
                    required: true
                },
                "deal_pricing_request[details]": {
                    required: true
                },
                "deal_pricing_request[deadline]": {
                    required: true
                }
            },
            messages: {
                'deal_pricing_request[incoterm_id]': 'Incoterm must be selected',
                'deal_pricing_request[details]': 'Please provide the additional information you need from suppliers',
                'deal_pricing_request[deadline]': 'Minimum 1 week from today'
            },
            errorPlacement: (error, element) => {
                if (element.attr('name') === 'deal_pricing_request[details]') {
                    error.insertAfter(element.parent());
                } else {
                    error.insertAfter(element);
                }
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    },

    initWizard() {
        $('#supply-task-request-smartwizard').smartWizard({
            theme: "dots",
            justified: true,
            autoAdjustHeight: true,
            enableURLhash: false,
            transition: {
                animation: 'fade',
                speed: '400',
                easing: ''
            },
            keyboardSettings: {
                keyNavigation: false
            },
            toolbarSettings: {
                toolbarButtonPosition: 'center',
                showNextButton: false,
                showPreviousButton: false
            },
            anchorSettings: {
                anchorClickable: true,
                enableAllAnchors: false,
                markDoneStep: false,
                markAllPreviousStepsAsDone: false,
                removeDoneStepOnNavigateBack: true
            }
        });

        $("#supply-task-request-smartwizard").on("leaveStep", function (e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) {
            if (stepDirection != "forward") {
                return true;
            }

            let valid = [];

            if (currentStepIndex == 0) {
                valid.push($("[name='deal_sample_request[sample_title]']").valid());
                valid.push($("textarea[name='deal_sample_request[sample_details]']").valid());
                valid.push($("input[name='deal_sample_request[recipient_name]']").valid());
                valid.push($("input[name='deal_sample_request[delivery_address]']").valid());
                valid.push($("input[name='deal_sample_request[deadline]']").valid());

                if (!valid.every(Boolean)) {
                    return false
                }
            } else if (currentStepIndex == 1) {
                valid.push($("[name='deal_information_request[title]']").valid());
                valid.push($("textarea[name='deal_information_request[details]']").valid());
                valid.push($("input[name='deal_information_request[deadline]']").valid());

                if (!valid.every(Boolean)) {
                    return false
                }
            } else if (currentStepIndex == 2) {
                valid.push($("[name='deal_pricing_request[title]']").valid());
                valid.push($("textarea[name='deal_pricing_request[details]']").valid());
                valid.push($("input[name='deal_pricing_request[deadline]']").valid());
                valid.push($("select[name='deal_pricing_request[incoterm_id]']").valid());

                if (!valid.every(Boolean)) {
                    return false
                }
            } else if (currentStepIndex == 3) {
                if (nextStepIndex == 4) {
                    CreateSupplyTaskRequest.handleReviewScreen();
                }
            }

            return !valid.includes(false);
        });

        if (document.querySelector('#step-0') != null) {
            resizeObserver.observe(document.querySelector('#step-0'));
            resizeObserver.observe(document.querySelector('#step-1'));
            resizeObserver.observe(document.querySelector('#step-2'));
            resizeObserver.observe(document.querySelector('#step-3'));
            resizeObserver.observe(document.querySelector('#step-4'));
        }
    },

    handleModalShow() {
        $('#supply-task-request-modal').on('show.bs.modal', function () {
            let allSteps = [0, 1, 2, 3, 4];
            let activeStepsString = document.getElementById("selected_supply_tasks").value;
            activeSteps = activeStepsString.split(',').map(Number);
            if (!activeSteps.includes(3)) {
                activeSteps.push(3);
            }
            if (!activeSteps.includes(4)) {
                activeSteps.push(4);
            }
            activeSteps.sort();
            let hiddenSteps = allSteps.filter(x => !activeSteps.includes(x))
            $('#supply-task-request-smartwizard').smartWizard("stepState", hiddenSteps, "hide");
            $('#supply-task-request-smartwizard').smartWizard("stepState", activeSteps, "show");

            CreateSupplyTaskRequest.resetSampleRequestForm();
            CreateSupplyTaskRequest.resetInformationRequestForm();
            CreateSupplyTaskRequest.resetPricingRequestForm();
            CreateSupplyTaskRequest.resetSupplierSelectionForm();
            CreateSupplyTaskRequest.resetReviewScreen();

            $('#supply-task-request-smartwizard').smartWizard("reset");

            $('#sample-request-form').valid();
            $('#information-request-form').valid();
            $('#pricing-request-form').valid();

            const errorElems = document.querySelectorAll('.error');
            errorElems.forEach(el => el.classList.remove('error'));

            $('#supply-task-request-smartwizard').smartWizard("goToStep", activeSteps[0]);
        });
    },

    handleButtonClicks() {
        $("#samples-continue-modal-btn").click(function () {
            $('#supply-task-request-smartwizard').smartWizard("next");
        });

        $("#rfi-continue-modal-btn").click(function () {
            $('#supply-task-request-smartwizard').smartWizard("next");
        });

        $("#pricing-continue-modal-btn").click(function () {
            $('#supply-task-request-smartwizard').smartWizard("next");
        });

        $("#suppliers-continue-modal-btn").click(function () {
            $('#supply-task-request-smartwizard').smartWizard("next");
        });

        $("#edit-sample-request-btn").click(function () {
            $('#supply-task-request-smartwizard').smartWizard("goToStep", 0);
        });

        $("#edit-information-request-btn").click(function () {
            $('#supply-task-request-smartwizard').smartWizard("goToStep", 1);
        });

        $("#edit-pricing-request-btn").click(function () {
            $('#supply-task-request-smartwizard').smartWizard("goToStep", 2);
        });

        $("#edit-suppliers-selected-btn").click(function () {
            $('#supply-task-request-smartwizard').smartWizard("goToStep", 3);
        });

        $("#create-supply-tasks-modal-btn").click(async function () {
            try {
                if (activeSteps.includes(0)) {
                    await CreateSupplyTaskRequest.submitSampleRequest();
                }

                if (activeSteps.includes(1)) {
                    await CreateSupplyTaskRequest.submitInformationRequest();
                }

                if (activeSteps.includes(2)) {
                    await CreateSupplyTaskRequest.submitPricingRequest();
                }

                $('#supply-task-request-modal').modal('hide');
                window.location.reload();
            } catch (error) {
                console.error("An error occurred while processing the requests:", error);
                $('#supply-task-request-modal').modal('hide');
                window.location.reload();
            }
        });
    },

    submitSampleRequest() {
        return new Promise((resolve, reject) => {
            let dealUUID = $('#sample-request-form [name="deal_sample_request[deal_uuid]"]').val();
            let sampleTitle = $('#sample-request-form [name="deal_sample_request[sample_title]"]').val();
            let sampleDetails = $('#sample-request-form textarea[name="deal_sample_request[sample_details]"]').val();
            let recipientName = $('#sample-request-form input[name="deal_sample_request[recipient_name]"]').val();
            let deliveryAddress = $('#sample-request-form input[name="deal_sample_request[delivery_address]"]').val();
            let deadline = $('#sample-request-form input[name="deal_sample_request[deadline]"]').val();
            let suppliersIds = selectedSuppliers.map(team => team.id);

            $.ajax({
                url: '/deal_sample_requests',
                method: 'POST',
                data: {
                    deal_uuid: dealUUID,
                    sample_title: sampleTitle,
                    sample_details: sampleDetails,
                    recipient_name: recipientName,
                    delivery_address: deliveryAddress,
                    deadline: deadline,
                    supplier_team_ids: suppliersIds
                },
                success: resolve,
                error: reject
            });
        });
    },

    submitInformationRequest() {
        return new Promise((resolve, reject) => {
            let dealUUID = $('#information-request-form [name="deal_information_request[deal_uuid]"]').val();
            let title = $('#information-request-form [name="deal_information_request[title]"]').val();
            let details = $('#information-request-form textarea[name="deal_information_request[details]"]').val();
            let deadline = $('#information-request-form input[name="deal_information_request[deadline]"]').val();
            let suppliersIds = selectedSuppliers.map(team => team.id);
            const attachmentIds = Array.from(
                document.querySelectorAll('#information-request-form input[name="deal_information_request[deal_information_request_attachment_ids][]"]')
            ).map(elm => elm.value);

            $.ajax({
                url: '/deal_information_requests',
                method: 'POST',
                data: {
                    deal_uuid: dealUUID,
                    title: title,
                    details: details,
                    deadline: deadline,
                    supplier_team_ids: suppliersIds,
                    deal_information_request_attachment_ids: attachmentIds
                },
                success: resolve,
                error: reject
            });
        });
    },

    submitPricingRequest() {
        return new Promise((resolve, reject) => {
            let dealUUID = $('#pricing-request-form [name="deal_pricing_request[deal_uuid]"]').val();
            let title = $('#pricing-request-form [name="deal_pricing_request[title]"]').val();
            let details = $('#pricing-request-form textarea[name="deal_pricing_request[details]"]').val();
            let deadline = $('#pricing-request-form input[name="deal_pricing_request[deadline]"]').val();
            let incoterm_id = $('#pricing-request-form select[name="deal_pricing_request[incoterm_id]"]').val();
            let destination = $('#pricing-request-form input[name="deal_pricing_request[destination]"]').val();
            let suppliersIds = selectedSuppliers.map(team => team.id);
            const attachmentIds = Array.from(
                document.querySelectorAll('#pricing-request-form input[name="deal_pricing_request[deal_pricing_request_attachment_ids][]"]')
            ).map(elm => elm.value);

            $.ajax({
                url: '/deal_pricing_requests',
                method: 'POST',
                data: {
                    deal_uuid: dealUUID,
                    title: title,
                    details: details,
                    deadline: deadline,
                    incoterm_id: incoterm_id,
                    destination: destination,
                    supplier_team_ids: suppliersIds,
                    deal_pricing_request_attachment_ids: attachmentIds
                },
                success: resolve,
                error: reject
            });
        });
    },

    handleHiddenSteps() {
        const navLinks = document.querySelectorAll('ul.nav.m-auto li a.nav-link');
        navLinks.forEach((navLink) => {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'class') {
                        const parentLi = navLink.parentElement;
                        if (parentLi) {
                            if (navLink.classList.contains('hidden')) {
                                parentLi.classList.add('hidden');
                            } else {
                                parentLi.classList.remove('hidden');
                            }
                        }
                    }
                });
            });

            observer.observe(navLink, {
                attributes: true
            });
        });
    },

    handleSupplierSelection() {
        const selectAllSuppliersCheckbox = document.getElementById('select-all-suppliers');
        const supplierCheckboxes = document.querySelectorAll('#supplier-select-form-view input[type="checkbox"]');
        const confirmSelectionButton = document.getElementById('suppliers-continue-modal-btn');


        selectAllSuppliersCheckbox.addEventListener('change', function () {
            supplierCheckboxes.forEach(function (checkbox) {
                checkbox.checked = selectAllSuppliersCheckbox.checked;
            });
            updateButtonState();
        });

        supplierCheckboxes.forEach(function (checkbox) {
            checkbox.addEventListener('change', updateButtonState);
        });

        updateButtonState();

        function updateButtonState() {
            var anyCheckboxSelected = false;
            selectedSuppliers = [];
            supplierCheckboxes.forEach(function (checkbox) {
                if (checkbox.checked) {
                    anyCheckboxSelected = true;
                } else {
                    selectAllSuppliersCheckbox.checked = false;
                }

                if (checkbox.checked && checkbox != selectAllSuppliersCheckbox) {
                    const team = {"name": checkbox.dataset.teamName, "id": checkbox.dataset.teamId};
                    selectedSuppliers.push(team);
                }
            });

            confirmSelectionButton.disabled = !anyCheckboxSelected;
        }
    },

    handleReviewScreen() {
        document.querySelector('#sample-request-review').style.display = 'none';
        document.querySelector('#information-request-review').style.display = 'none';
        document.querySelector('#pricing-request-review').style.display = 'none';

        if (activeSteps.includes(0)) {
            CreateSupplyTaskRequest.sampleRequestReview();
        }

        if (activeSteps.includes(1)) {
            CreateSupplyTaskRequest.informationRequestReview();
        }

        if (activeSteps.includes(2)) {
            CreateSupplyTaskRequest.pricingRequestReview();
        }

        let selectedSuppliersText = selectedSuppliers.map(team => team.name).join(', ');
        $('#supplier-names-review').text(selectedSuppliersText);
    },

    resetSampleRequestForm() {
        const date = new Date();
        date.setDate(date.getDate() + 14);
        const dateFormat = date.toISOString().split('T')[0];

        let userName = document.getElementById("current_user_name").value;
        let teamAddress = document.getElementById("team_address").value;

        $('#sample-request-form textarea[name="deal_sample_request[sample_details]"]').val("");
        $('#sample-request-form input[name="deal_sample_request[recipient_name]"]').val(userName);
        $('#sample-request-form input[name="deal_sample_request[delivery_address]"]').val(teamAddress);
        $('#sample-request-form input[name="deal_sample_request[deadline]"]').val(dateFormat);
    },

    resetInformationRequestForm() {
        const date = new Date();
        date.setDate(date.getDate() + 2);
        const dateFormat = date.toISOString().split('T')[0];

        $('#information-request-form textarea[name="deal_information_request[details]"]').val("");
        $('#information-request-form input[name="deal_information_request[deadline]"]').val(dateFormat);

        let attachmentElements = document.querySelectorAll('#information-request-form input[name="deal_information_request[deal_information_request_attachment_ids][]"]')
        attachmentElements.forEach(function (attachmentElement) {
            attachmentElement.remove();
        });
        $('#deal-information-request-attachments').children('.dz-preview').remove();
    },

    resetPricingRequestForm() {
        const date = new Date();
        date.setDate(date.getDate() + 7);
        const dateFormat = date.toISOString().split('T')[0];

        $('#pricing-request-form textarea[name="deal_pricing_request[details]"]').val("");
        $('#pricing-request-form input[name="deal_pricing_request[deadline]"]').val(dateFormat);
        $('#pricing-request-form input[name="deal_pricing_request[destination]"]').val("");

        const incotermElement = document.getElementById("deal_pricing_request[incoterm_id]");
        for (let option of incotermElement.options) {
            if (option.text === 'DDP') {
                incotermElement.value = option.value;
                break;
            }
        }
        incotermElement.dispatchEvent(new Event('change'));


        let attachmentElements = document.querySelectorAll('#information-request-form input[name="deal_information_request[deal_information_request_attachment_ids][]"]')
        attachmentElements.forEach(function (attachmentElement) {
            attachmentElement.remove();
        });
        $('#deal-pricing-request-attachments').children('.dz-preview').remove();
    },

    resetSupplierSelectionForm() {
        $('input[type="checkbox"]:enabled').prop('checked', false);
        CreateSupplyTaskRequest.handleSupplierSelection();
    },

    resetReviewScreen() {
        $('#sample-details-review').text('');
        $('#recipient-name-review').text('');
        $('#destination-review').text('');
        $('#sample-deadline-review').text('');

        $('#information-details-review').text('');
        $('#information-deadline-review').text('');
    },

    sampleRequestReview() {
        document.querySelector('#sample-request-review').style.display = 'block';

        let sampleDetailsText = $('#sample-request-form textarea[name="deal_sample_request[sample_details]"]').val();
        $('#sample-details-review').text('Sample Details: ' + sampleDetailsText);

        let recipientNameText = $('#sample-request-form input[name="deal_sample_request[recipient_name]"]').val();
        $('#recipient-name-review').text('Recipient\'s Name: ' + recipientNameText);

        let deliveryAddressText = $('#sample-request-form input[name="deal_sample_request[delivery_address]"]').val();
        $('#delivery-address-review').text('Delivery Address: ' + deliveryAddressText);

        let deadlineText = $('#sample-request-form input[name="deal_sample_request[deadline]"]').val();
        let deadlineDate = new Date(deadlineText);

        let day = deadlineDate.getDate();
        let month = deadlineDate.toLocaleString('default', {month: 'long'});
        let year = deadlineDate.getFullYear();

        let formattedDeadline = `${day} ${month} ${year}`;
        $('#sample-deadline-review').text('Deadline: ' + formattedDeadline);
    },

    informationRequestReview() {
        document.querySelector('#information-request-review').style.display = 'block';

        let informationDetailsText = $('#information-request-form textarea[name="deal_information_request[details]"]').val();
        $('#information-details-review').text('Additional Information Required: ' + informationDetailsText);

        let deadlineText = $('#information-request-form input[name="deal_information_request[deadline]"]').val();
        let deadlineDate = new Date(deadlineText);

        let day = deadlineDate.getDate();
        let month = deadlineDate.toLocaleString('default', {month: 'long'});
        let year = deadlineDate.getFullYear();

        let formattedDeadline = `${day} ${month} ${year}`;
        $('#information-deadline-review').text('Deadline: ' + formattedDeadline);
    },

    pricingRequestReview() {
        document.querySelector('#pricing-request-review').style.display = 'block';

        let pricingDetailsText = $('#pricing-request-form textarea[name="deal_pricing_request[details]"]').val();
        $('#pricing-details-review').text('Pricing Details: ' + pricingDetailsText);

        var incotermSelect = document.getElementById('deal_pricing_request[incoterm_id]');
        let pricingTypeText = incotermSelect.options[incotermSelect.selectedIndex].text
        $('#pricing-incoterm-review').text('Incoterm: ' + pricingTypeText);

        let pricingDestinationText = $('#pricing-request-form input[name="deal_pricing_request[destination]"]').val();
        if (pricingDestinationText) {
            $('#pricing-destination-review').text('Destination: ' + pricingDestinationText);
        }

        let deadlineText = $('#pricing-request-form input[name="deal_pricing_request[deadline]"]').val();
        let deadlineDate = new Date(deadlineText);

        let day = deadlineDate.getDate();
        let month = deadlineDate.toLocaleString('default', {month: 'long'});
        let year = deadlineDate.getFullYear();

        let formattedDeadline = `${day} ${month} ${year}`;
        $('#pricing-deadline-review').text('Deadline: ' + formattedDeadline);
    }
};
